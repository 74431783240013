import Vue from 'vue';
import AddToCartButton from '@/components/AddToCartButton.vue'
Vue.component('AddToCartButton', AddToCartButton);
Vue.component('AddToCartModal', () => import('@/components/cart/AddToCartModal.vue'));
Vue.component('AddToCartStock', () => import('@/components/AddToCartStock.vue'));
Vue.component('BulkOrder', () => import('@/components/bulk-order/BulkOrder.vue'));
Vue.component('CartComponent', () => import('@/components/cart/CartComponent.vue'));
Vue.component('CartComponentRelatedProducts', () => import('@/components/cart/CartComponentRelatedProducts.vue'));
Vue.component('CartPreview', () => import('@/components/cart/cart-preview/CartPreview.vue'));
Vue.component('Checkout', () => import('@/components/checkout/Checkout.vue'));
Vue.component('ContactForm', () => import('@/components/content/ContactForm.vue'));
Vue.component('CsvImport', () => import('@/components/csv-import/CsvImport.vue'));
Vue.component('CustomerAreaDashboard', () => import('@/components/customerarea/CustomerAreaDashboard.vue'));
Vue.component('CustomerProfileModal', () => import('@/components/customer-profile/CustomerProfileModal.vue'));
Vue.component('CustomerProfileSelector', () => import('@/components/customer-profile/CustomerProfileSelector.vue'));
Vue.component('DealerLocator', () => import('@/components/DealerLocator.vue'));
Vue.component('DetailsAddonProducts', () => import('@/components/product-details/DetailsAddonProducts.vue'));
import DetailsAddToCart from '@/components/product-details/DetailsAddToCart.vue';
Vue.component('DetailsAddToCart', DetailsAddToCart );
import DetailsAddToWishList from '@/components/product-details/DetailsAddToWishList.vue';
Vue.component('DetailsAddToWishList', DetailsAddToWishList);
Vue.component('DetailsBulkDiscountsAddToCart', () =>
    import('@/components/product-details/DetailsBulkDiscountsAddToCart.vue')
);
Vue.component('DetailsDiscount', () => import('@/components/product-details/DetailsDiscount.vue'));
import DetailsItemnumber from '@/components/product-details/DetailsItemnumber.vue';
Vue.component('DetailsItemnumber', DetailsItemnumber);
import DetailsPrice from '@/components/product-details/DetailsPrice.vue';
Vue.component('DetailsPrice', DetailsPrice);
Vue.component('DetailsRelatedProducts', () => import('@/components/product-details/DetailsRelatedProducts.vue'));
Vue.component('DetailsStock', () => import('@/components/product-details/DetailsStock.vue'));
Vue.component('ExternalScript', () => import('@/components/utils/ExternalScript.vue'));
Vue.component('GlobalSearch', () => import('@/components/search/GlobalSearch.vue'));
Vue.component('GlobalSearchOverlay', () => import('@/components/search/GlobalSearchOverlay.vue'));
Vue.component('GlobalSearchOverlayV2', () => import('@/components/search/GlobalSearchOverlayV2.vue'));
Vue.component('GoToProduct', () => import('@/components/go-to-product/GoToProduct.vue'));
import HeaderSearch from '@/components/search/HeaderSearch.vue';
Vue.component('HeaderSearch', HeaderSearch);
Vue.component('Image360', () => import('@/components/product-details/Image360.vue'));
Vue.component('LabelEditor', () => import('@/components/labels/LabelEditor.vue'));
Vue.component('Login', () => import('@/components/login/Login.vue'));
import MobileHeaderBottom from '@/components/mobile-header/MobileHeaderBottom.vue';
Vue.component('MobileHeaderBottom', MobileHeaderBottom);
Vue.component('MobileHeaderTopActions', () => import('@/components/mobile-header/MobileHeaderTopActions.vue'));
Vue.component('MobileNavigation', () => import('@/components/navigation/MobileNavigation.vue'));
Vue.component('MobileNavigationV2', () => import('@/components/mobile-navigation/MobileNavigation.vue'));
import MobileNavigationV2Button from '@/components/mobile-navigation/MobileNavigationButton.vue';
Vue.component('MobileNavigationV2Button', MobileNavigationV2Button);
Vue.component('Modal', () => import('@/components/modal/Modal.vue'));
Vue.component('ModalHeader', () => import('@/components/modal/ModalHeader.vue'));
Vue.component('NewCustomer', () => import('@/components/NewCustomer.vue'));
Vue.component('NewsletterSubscriptionForm', () => import('@/components/content/NewsletterSubscriptionForm.vue'));
Vue.component('PriceOutput', () => import('@/components/utils/PriceOutput.vue'));
Vue.component('ProductCard', () => import('@/components/utils/ProductCard.vue'));
Vue.component('ProductImages', () => import('@/components/product-details/ProductImages.vue'));
// import ProductImages from '@/components/product-details/ProductImages.vue';
// Vue.component('ProductImages', ProductImages);
Vue.component('ProductList', () => import('@/components/ProductList.vue'));
Vue.component('ProductListFilter', () => import('@/components/product-list-filter/ProductListFilter.vue'));
Vue.component('ProductListFilterMobile', () =>
    import('@/components/product-list-filter-mobile/ProductListFilterMobile.vue')
);
Vue.component('ProductListFilterPagination', () =>
    import('@/components/product-list-filter/ProductListFilterPagination.vue')
);
Vue.component('ProductListMobile', () => import('@/components/ProductListMobile.vue'));
Vue.component('ProductListPaged', () => import('@/components/ProductListPaged.vue'));
import ProductSlider from '@/components/ProductSlider.vue';
Vue.component('ProductSlider', ProductSlider);
Vue.component('SupervisorPanel', () => import('@/components/supervisor/SupervisorPanel.vue'));
import TopCart from '@/components/cart/TopCart.vue';
Vue.component('TopCart', TopCart);
import TopLogin from '@/components/login/TopLogin.vue';
Vue.component('TopLogin', TopLogin);
Vue.component('VariantSelector', () => import('@/components/product-details/VariantSelector.vue'));
Vue.component('GlassConfigurator', () => import('@/components/client/glass-configurator/GlassConfigurator.vue'));
