export function toCssClassName(prefix: string, input: string) {
    return prefix + '-' + input.replace(/([^a-z0-9])/gi, '-').toLowerCase();
}

export function uuid() {
    const url = URL.createObjectURL(new Blob());
    const id = url.split('/').pop();
    URL.revokeObjectURL(url);
    return id;
}

export function toTranslationKey(input: string) {
    return input?.replace(/([^a-z0-9]+)/gi, '-').toLowerCase() ?? '';
}